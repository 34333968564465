import headerRfp from '../headerRfp';
import item from '../item';
import boq from '../boq';
import vendorDetails from '../vendorDetails';
import locationDetails from '../locationDetails';
import negotiationRounds from '../negotiationRounds';
import termsAndConditions from '../termsAndConditions';
import extendRfp from '../extendRfp';
import boqFiles from "../boqFiles";


export default {
  name: 'addRequest',
  props: [
    'eventId',
    'headerAllData',
    'locationAllData',
    'itemAllData',
    'vendorAllData',
    'negotiationAllData',
    'rfphdrid',
    'getAllRfpData',
    'userId',
    'boqAllData',
    'tncData',
    'extendData',
    'menuFromDashboard',
    'wfDtlId'
  ],
  components: {
    headerRfp,
    item,
    boq,
    vendorDetails,
    locationDetails,
    negotiationRounds,
    termsAndConditions,
    extendRfp,
    boqFiles
  },
  data() {
    return {
      loading: false,
      data: null,
      editMode: true,
      headerId: null,
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      weightage: 0,
      itemBasis: null,
      showCopyOpenRfp: false,
      requiredRfp: null,
      requiredRfpHeaderId: null,
      requiredToHeaderId: null,
      showApproveModal:false,
      showRejectModal:false,
      approvalReason:null,
      rejectedReason:null,
      showAlert: false,
      responseMsg: null,
      isSuccess: false,
      roundData: []
    };
  },
  mounted() {
    this.getRfpAllDataByHeaderId()
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'save') {
    //       this.postRfpData();
    //     }
    //   }
    // });
  },
  methods: {
    hideAdditionalInformationModal() {
      this.showOpenAdditionalInformationModal = false;
    },
    openAdditionalInformationModal() {
      this.showOpenAdditionalInformationModal = true;
    },
    postRfpData() {
      let rfpItemDetails = [];
      for (let i of this.itemData) {
        let rfpItemAttributeDetails = [];
        for (let j of i.itemAttributeData) {
          rfpItemAttributeDetails.push({
            item_attribute_id: 0,
            attribute_code: j.attribute_code,
            attribute_description: j.attribute_description,
            attribute_specification: j.attribute_specification,
            weightage: +j.weightage,
            delete_flag: j.delete_flag
          });
        }
        rfpItemDetails.push({
          rfp_item_id: 0,
          item_id: 200,
          item_code: i.item_code,
          item_description: i.item_description,
          item_long_description: i.item_long_description,
          uom: i.uom,
          quantity: +i.quantity,
          attribute_1: i.attribute_1,
          attribute_2: i.attribute_2,
          attribute_3: i.attribute_3,
          attribute_4: i.attribute_4,
          attribute_5: i.attribute_5,
          delete_flag: i.delete_flag,
          rfp_item_attribute_dtls: rfpItemAttributeDetails
        });
      }
      let payload = {
        rfp_header_id: 0,
        rfp_number: null,
        approval_status: this.approvalStatus,
        start_date: this.startDate,
        end_date: this.endDate,
        rfp_status: this.rfpStatus.value,
        description: this.description,
        rfp_type: this.rfpType.value,
        user_department: this.department,
        buyer_emp_id: 182,
        attribute_1: this.attribute1,
        attribute_2: this.attribute2,
        attribute_3: this.attribute3,
        attribute_4: this.attribute4,
        attribute_5: this.attribute5,
        version: +this.version,
        rfp_item_dtls: rfpItemDetails
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postRfpInsertionData', payload)
        .then(response => {
          if (response.status === 200) {
            this.headerId = response.data.data.rfp_header_id;
            this.editMode = false;
            this.getRfpData();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRfpData() {
      this.editMode = false;
      const payload = {
        rfp_header_id: this.headerId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpInsertionData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.approvalStatus.text = result.approval_status;
            this.attribute1 = result.attribute_1;
            this.attribute2 = result.attribute_2;
            this.attribute3 = result.attribute_3;
            this.attribute4 = result.attribute_4;
            this.attribute5 = result.attribute_5;
            this.rfpNumber = result.rfp_number;
            this.rfpStatus.text = result.rfp_status;
            this.startDate = result.start_date;
            this.endDate = result.end_date;
            this.description = result.description;
            this.userDepartment.text = result.user_department;
            this.rfpType.text = result.rfp_type;
            this.version = result.version;
            this.itemData = result.rfp_item_dtls;
            for (let i = 0; i < result.rfp_item_dtls.length; i++) {
              this.itemData[i].itemAttributeData =
                result.rfp_item_dtls[i].rfp_item_attribute_dtls;
            }
            setTimeout(() => {
              this.itemData = JSON.parse(JSON.stringify(this.itemData));
            }, 0);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectBoxChecked(flag, index) {
      this.itemData[index].delete_flag = flag;
    },
    selectBoxAttributeChecked(flag, index) {
      this.itemData[this.itemAttributeIndex].itemAttributeData[
        index
      ].delete_flag = flag;
    },
    getHeaderData() {
      this.$refs.header.getHeaderData();
    },
    getRfpAllData(headerid) {
      this.$emit('getAllRfp', headerid);
    },
    closeCopyRfp(flag) {
      this.showCopyOpenRfp = flag;
    },
    openCopyRfp() {
      this.showCopyOpenRfp = true;
    },
    getRfpGridData() {
      if (this.requiredRfp === null) {
        alert('RFP Number is mandatory');
      } else {
        let payload = {
          _limit: this.perPage,
          _page: 0,
          rfp_number: this.requiredRfp,
          approval_status: null,
          rfp_status: null,
          start_date: null,
          end_date: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpGridData', payload)
          .then(response => {
            if (response.status === 200) {
              this.requiredRfpHeaderId =
                response.data.data.page[0].rfp_header_id;
              this.copyRfpFromExistingRfp();
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    copyRfpFromExistingRfp() {
      let rfpCopyDetails = [
        {
          to_rfp_header_id: 0
        }
      ];
      let payload = {
        from_rfp_header_id: this.requiredRfpHeaderId,
        rfp_copy_dtls: rfpCopyDetails
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postCopyRfpData', payload)
        .then(response => {
          if (response.status === 200) {
            this.requiredToHeaderId = response.data.data;
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.$emit('getGridData');
            this.showCopyOpenRfp = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err, 'success');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast'
      });
    },
    showHideApproveModal(flag) {
      this.showApproveModal = flag;
    if (!flag) {
      this.approvalReason = null;
    }
  },
  approveRejectRfp(status) {
    const payload = {
      event_id: this.eventId,
      wf_inprocess_dtl_id: this.wfDtlId,
      notification_status: status,
      notification_comments:
        status === 'REJECTED' ? this.rejectedReason : this.approvalReason
    };
    this.loading = true;
    this.$store
      .dispatch('intWorkflow/approvalRejectSingle', payload)
      .then(response => {
        this.loading = false;
        this.showAlert = true;
        if (response.status === 200) {
          this.editMode = false;
          this.isSuccess = true;
          this.responseMsg = response.data.message;
          if (status === 'REJECTED') {
            this.showHideRejectModal(false);
          } else if (status === 'APPROVED') {
            this.showHideApproveModal(false);
            this.getRfpAllDataByHeaderId();
          }
        } else {
          this.showAlert = false;
          alert(response.response.data.message);
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  showHideRejectModal(flag) {
    this.showRejectModal = flag;
    if (!flag) {
      this.rejectedReason = null;
    }
  },
  getRfpAllDataByHeaderId() {
    const payload = {
      headerId: this.rfphdrid
    };
    this.loading = true;
    this.$store
      .dispatch('rfp/getRfpDataByHeaderId', payload)
      .then(response => {
        if (response.status === 200) {
          let result = response.data.data;
          this.roundData = result.rfp_negotiation_dtls;
          if(result.approval_status === "APPROVED" && result.rfp_negotiation_dtls[this.roundData.length-1].rfp_initiation_round_wf_status === 0) {
            this.sendBid();
          }
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  sendBid() {
    let vendor = [];
    for (let i of this.roundData[this.roundData.length-1].negotiation_vendor_dtls) {
      vendor.push({
        negotiation_vendor_id: i.negotiation_vendor_id,
        rfp_vendor_id: i.rfp_vendor_id
      });
    }
    let payload = {
      negotiation_hdr_id: this.roundData[this.roundData.length-1].negotiation_hdr_id,
      rfp_header_id: this.roundData[this.roundData.length-1].rfp_header_id,
      negotiation_seq: this.roundData[this.roundData.length-1].negotiation_seq,
      status: 'INITIATED',
      negotiation_vendor_dtls: vendor
    };
    this.$store
      .dispatch('rfp/postNegotiationDetails', payload)
      .then(response => {
        if (response.status === 200) {
          this.responseMsg = response.data.message;
          this.makeToast(this.responseMsg, 'success');
        } else {
          this.loading = false;
          this.responseMsg = response.response.data.message;
          this.makeToast(this.responseMsg, 'warning');
        }
      })
      .catch(err => {
        this.loading = false;
        this.makeToast(err.message, 'danger');
      });
  },
  },
  beforeDestroy() {
    this.eventBus.$off('itemCode');
    // this.unsubscribe();
  }
};
