export default {
  name: 'qAndA',
  props: ['rfpHeaderId', 'negotiationAllData'],
  data() {
    return {
      question: null,
      questionsFromVendors: [],
      userId: null,
      loading: false,
      selectedRound: 1,
      roundOptions: []
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.getQuestionsFromVendors();
    this.createRounds();
  },
  methods: {
    formatTime(dateTime) {
      return new Date(dateTime).toLocaleTimeString();
    },
    getQuestionsFromVendors() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_hdr_id: this.negotiationAllData[this.selectedRound-1].negotiation_hdr_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getQuestionsFromVendors', payload)
        .then(response => {
          if (response.status === 200) {
            this.questionsFromVendors = response.data.data;
            for(let i of this.questionsFromVendors) {
                if(i.answer_text === null || i.answer_text === "") {
                    i.answered = false;
                } else {
                    i.answered = true;
                }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sendAnswer(message) {
      const payload = {
        rfp_question: 0,
        rfp_question_id: message.rfp_question_id,
        rfp_sub_question_id: message.rfp_sub_question_id,
        rfp_header_id: this.rfpHeaderId,
        negotiation_hdr_id: this.negotiationAllData[this.selectedRound-1].negotiation_hdr_id,
        vendor_code: message.vendor_code,
        buyer_code: this.userId,
        question_text: 'null',
        answer_text: message.answer_text
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postQuestionsFromVendors', payload)
        .then(response => {
          if (response.status === 200) {
            this.getQuestionsFromVendors();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createRounds() {
        for(let i=0; i<this.negotiationAllData.length; i++) {
            this.roundOptions.push(i+1);
        }
    }
  }
};
