import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../shared/responseModal';
import commonHelper from '@/app/utility/common.helper.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import qAndA from '../qAndA';


export default {
  name: 'headerRfp',
  props: ['headerAllData', 'menuFromDashboard', 'negotiationAllData'],
  components: {
    responseModal,
    UploadDocument,
    qAndA
  },
  data() {
    return {
      loading: false,
      leName: {
        value: null,
        text: null
      },
      rfpNumber: null,
      startDate: null,
      endDate: null,
      description: null,
      userDepartment: {
        value: null,
        text: null
      },
      rfpType: {
        value: null,
        text: null
      },
      approvalStatus: {
        value: 'DRAFT',
        text: 'DRAFT'
      },
      rfpStatus: {
        value: 'DRAFT',
        text: 'DRAFT'
      },
      version: 0,
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      editMode: true,
      showOpenUserDepartment: false,
      userDepartmentData: [],
      userDepartmentFields: [
        {
          key: 'concatenated_segment'
        },
        {
          key: 'concatenated_segment_meaning'
        }
      ],
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      userDepartmentTotalRows: null,
      userDepartmentCurrentPage: 1,
      rfpHeaderId: 0,
      currentDate: null,
      responseMsg: null,
      rfpBasis: null,
      rfpClosedFlag: false,
      showOpenClosedRfpModal: false,
      rfpInitiateBidFlag: false,
      remarks: null,
      autofocus: true,
      headerRemarks: null,
      userId: null,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      showHistory: false,
      historyType: null,
      popoverContent: null,
      currentPage: 1,
      documentList: [],
      rfpExtensionId: null,
      headerBudget: null,
      headerRetention: null,
      headerPaymentTerms: null,
      showOpenPaymentTerms: null,
      paymentTermsData: [],
      paymentTermsFields: [
        {
          key: 'payment_term_name'
        },
        {
          key: 'payment_term_desc'
        }
      ],
      paymentTermsTotalRows: null,
      paymentTermsCurrentPage: 1,
      paymentTerm: { value: null, text: null, id: null },
      showOpenCancelRfpModal: false,
      cancelRemarks: null,
      ownersModal: false,
      buyersModal: false,
      buyersData: [],
      buyersFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'buyer_code',
          label: 'Employee Code',
          class: 'text-center col-sm-1'
        },
        {
          key: 'buyer_name',
          label: 'Employee Name',
          class: 'text-center'
        },
        {
          key: 'buyer_email',
          label: 'Employee Email',
          class: 'text-center'
        },
        {
          key: 'write_access'
        }
      ],
      selectedRow: [],
      selectAllCheckBox: false,
      ownersData: [],
      ownersFields: [
        {
          key: 'remove',
          label: 'Remove',
          class: 'text-center'
        },
        {
          key: 'owner_name',
          class: 'text-center'
        },
        {
          key: 'owner_email',
          class: 'text-center'
        },
        {
          key: 'owner_code',
          class: 'text-center'
        },
        {
          key: 'owner_access',
          label: 'Write Access',
          class: 'text-center'
        }
      ],
      searchOwner: null,
      rfpWriteAccess: false,
      boqType: {
        value: null,
        text: null
      },
      selectedBuyersData: [],
      selectedBuyersFields: [
        {
          key: 'buyer_code',
          label: 'Employee Code',
          class: 'text-center'
        },
        {
          key: 'buyer_name',
          label: 'Employee Name',
          class: 'text-center'
        },
        {
          key: 'buyer_email',
          label: 'Employee Email',
          class: 'text-center'
        },
        {
          key: 'write_access'
        }
      ],
      qnaModal: false,
      messageCount: 0
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.eventBus.$emit('itemBasis', this.rfpBasis);
    const currentDate = new Date();
    this.currentDate = currentDate.toISOString().split('.')[0];
    this.currentDate = this.currentDate.substring(
      0,
      this.currentDate.length - 3
    );
    if (this.headerAllData) {
      if (
        +this.headerAllData.user_id !== +this.userId &&
        this.rfpWriteAccess === 0
      ) {
        this.rfpClosedFlag = true;
        this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
      }
      this.getParentToChildData(this.headerAllData);
    }
    this.formatDate();
    this.getUploadedDocDtl();
    this.getPaymentTermsData();
    if (this.rfpHeaderId) {
      this.getWriteAccess();
      this.getQuestionsFromVendors();
    }
  },
  computed: {
    minEndDate() {
      if (!this.endDate) return null;
      const endDateTime = new Date(this.endDate);
      endDateTime.setDate(endDateTime.getDate() + 1);
      return endDateTime.toISOString().slice(0, 16);
    }
  },
  created() {
    this.eventBus.$on('userDepartment', userDepartmentResponse => {
      this.userDepartment = {
        value: userDepartmentResponse.concatenated_segment,
        text: userDepartmentResponse.concatenated_segment_meaning
      };
    });
    this.eventBus.$on('roundStatus', roundStatus => {
      this.roundStatus = roundStatus;
      if (this.roundStatus === 'CLOSED') {
        this.rfpStatus = {
          value: 'CLOSED FOR BIDDING',
          text: 'CLOSED FOR BIDDING'
        };
      } else if (this.roundStatus === 'OPEN') {
        this.rfpStatus = {
          value: 'OPEN FOR BIDDING',
          text: 'OPEN FOR BIDDING'
        };
      }
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('approvalstatus', approvalstatus => {
      this.approvalStatus = {
        value: approvalstatus,
        text: approvalstatus
      };
    });
    this.eventBus.$on('rfpExtensionId', rfpExtensionId => {
      this.rfpExtensionId = rfpExtensionId;
      if (this.rfpExtensionId) {
        this.getHeaderData();
      }
    });
  },
  watch: {
    userDepartmentCurrentPage: function() {
      this.getUserDepartmentData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserDepartmentData();
    }
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RFP_TYPE) {
        if (
          this.rfpBasis === 'BOQ' &&
          (item.value_code === 'NRC_OE' || item.value_code === 'RC_OE')
        ) {
          alert('You are not allowed for Open Envelope');
        } else {
          this.rfpType = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT) {
        this.userDepartment = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'RFP BOQ TYPE') {
        this.boqType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RFP_TYPE') {
        this.rfpType = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.leName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'USER_DEPARTMENT') {
        this.userDepartment = {
          value: null,
          text: null
        };
      }
    },
    clearDateTime(type) {
      if (type === 'startDateSpecific') {
        this.startDate = null;
      } else if (type === 'endDateSpecific') {
        this.endDate = null;
      }
    },
    openCloseResponseModal(item) {
      if (item === 'paymentTerms') {
        // this.getPaymentTermsData();
        this.showOpenPaymentTerms = true;
      }
    },
    paymentTermsModal(flag) {
      this.showOpenPaymentTerms = flag;
    },
    userDepartmentModal(flag) {
      this.showOpenUserDepartment = flag;
    },
    selectedUserDepartments(flag) {
      this.showOpenUserDepartment = flag;
    },
    getUserDepartmentData() {
      const payload = {
        concatenated_segment: null,
        le_id: this.leName.value,
        _page: this.userDepartmentCurrentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getUserDepartmentData', payload)
        .then(response => {
          if (response.status === 200) {
            this.userDepartmentData = response.data.data.page;
            this.userDepartmentTotalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // postHeaderData() {
    //   if (
    //     this.leName.value &&
    //     this.startDate &&
    //     this.endDate &&
    //     this.rfpBasis &&
    //     this.userDepartment.value &&
    //     this.rfpType.value &&
    //     this.approvalStatus.value &&
    //     this.rfpStatus.value
    //   ) {
    //     if (this.endDate <= this.startDate) {
    //       alert('End Date cannot be less than Start Date');
    //     } else if(this.startDate < this.currentDate) {
    //       alert('Start Date cannot be less than Current Date');
    //     } else {
    //       let payload = {
    //         rfp_header_id: this.rfpHeaderId,
    //         rfp_number: this.rfpNumber,
    //         approval_status: this.approvalStatus.value,
    //         start_date: this.startDate,
    //         end_date: this.endDate,
    //         rfp_status: this.rfpStatus.value,
    //         description: this.description,
    //         rfp_type: this.rfpType.value,
    //         user_department: this.userDepartment.value,
    //         le_id: +this.leName.value,
    //         le_name: this.leName.text,
    //         rfp_basis: this.rfpBasis
    //       };
    //       this.loading = true;
    //       this.$store
    //         .dispatch('rfp/postRfpHeaderData', payload)
    //         .then(response => {
    //           if (response.status === 200) {
    //             this.responseMsg = response.data.message;
    //             this.makeToast(this.responseMsg, 'success');
    //             this.rfpHeaderId = response.data.data.rfp_header_id;
    //             this.eventBus.$emit('headerId', this.rfpHeaderId);
    //             this.getHeaderData();
    //             this.editMode = false;
    //           }
    //         })
    //         .catch(err => {
    //           this.loading = false;
    //           this.makeToast(err, 'success');
    //         });
    //     }
    //   } else {
    //     alert('Please enter mandatory fields!');
    //   }
    // },
    postHeaderData() {
      if (
        this.rfpBasis === 'BOQ' &&
        (this.headerBudget === null ||
          this.headerRetention === null ||
          this.paymentTerm.text === null ||
          this.boqType.text === null)
        //      ||
        // (this.rfpBasis === 'item' &&
        //   (this.paymentTerm.text === null || this.paymentTerm.text === ''))
      ) {
        alert('Please fill in mandatory fields');
      } else {
        if (
          this.leName.value &&
          this.startDate &&
          this.endDate &&
          this.rfpBasis &&
          this.userDepartment.value &&
          this.rfpType.value &&
          this.approvalStatus.value &&
          this.rfpStatus.value
        ) {
          let startDate = new Date(this.startDate);
          let endDate = new Date(this.endDate);
          let currentDate = new Date(this.currentDate);
          let maxEndDate = this.addMonths(startDate, 6);
          if (endDate <= startDate) {
            alert('End Date cannot be less than or equal to Start Date');
          } else if (startDate < currentDate) {
            alert('Start Date cannot be less than Current Date');
          } else if (endDate > maxEndDate) {
            alert('End Date cannot be more than 6 months after Start Date');
          } else {
            let payload = {
              rfp_header_id: this.rfpHeaderId,
              rfp_number: this.rfpNumber,
              approval_status: this.approvalStatus.value,
              start_date: this.startDate,
              end_date: this.endDate,
              rfp_status: this.rfpStatus.value,
              description: this.description,
              rfp_type: this.rfpType.value,
              user_department: this.userDepartment.value,
              le_id: +this.leName.value,
              le_name: this.leName.text,
              rfp_basis: this.rfpBasis,
              budget: this.headerBudget,
              retention: this.headerRetention,
              payment_terms: this.paymentTerm.text,
              rfp_item_boq_type: this.boqType.text
            };
            this.loading = true;
            this.$store
              .dispatch('rfp/postRfpHeaderData', payload)
              .then(response => {
                if (response.status === 200) {
                  this.responseMsg = response.data.message;
                  this.makeToast(this.responseMsg, 'success');
                  this.rfpHeaderId = response.data.data.rfp_header_id;
                  this.eventBus.$emit('headerId', this.rfpHeaderId);
                  this.getHeaderData();
                  this.editMode = false;
                } else {
                  this.responseMsg = response.response.data.message;
                  this.makeToast(this.responseMsg, 'danger');
                }
              })
              .catch(err => {
                this.loading = false;
                this.makeToast(err, 'success');
              });
          }
        } else {
          alert('Please enter mandatory fields!');
        }
      }
    },
    getHeaderData() {
      let payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpHeaderData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.eventBus.$emit('headerData', result);
            this.leName = {
              value: result.le_id,
              text: result.le_name
            };
            this.rfpNumber = result.rfp_number;
            this.startDate = result.start_date;
            this.startDate = this.convertDateFormat(this.startDate);
            this.endDate = result.end_date;
            this.endDate = this.convertDateFormat(this.endDate);
            this.userDepartment = {
              value: result.user_department,
              text: result.user_department_meaning
            };
            this.rfpType = {
              value: result.rfp_type,
              text: result.rfp_type_meaning
            };
            this.eventBus.$emit('rfpType', this.rfpType.value);
            this.approvalStatus = {
              value: result.approval_status,
              text: result.approval_status
            };
            this.rfpStatus = {
              value: result.rfp_status,
              text: result.rfp_status
            };
            this.boqType = {
              value: result.rfp_item_boq_type,
              text: result.rfp_item_boq_type
            };
            this.eventBus.$emit('rfpStatus', this.rfpStatus);
            if (
              this.rfpStatus.text === 'CLOSED' ||
              this.rfpStatus.text === 'CANCELLED'
            ) {
              this.rfpClosedFlag = true;
              this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
            }
            this.version = result.version;
            this.description = result.description;
            this.headerRemarks = result.remarks;
            // if (this.headerRemarks) {
            //   this.rfpClosedFlag = true;
            //   this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
            // }
            this.editMode = false;
            this.rfpBasis = result.rfp_basis;
            this.headerBudget = result.budget;
            this.headerRetention = result.retention;
            this.paymentTerm.text = result.payment_terms;
            this.eventBus.$emit('itemBasis', this.rfpBasis);
            this.userId = result.buyer_emp_id;
            this.eventBus.$emit('userId', this.userId);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    getParentToChildData(data) {
      this.rfpHeaderId = data.rfp_header_id;
      this.selectedGridData = {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.getHeaderData();
    },
    convertDateFormat(inputDate) {
      const [datePart, timePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('-');
      const convertedDate = `${year}-${month}-${day}T${timePart}`;

      return convertedDate;
    },

    changeRfpBasis(item) {
      this.rfpBasis = item;
      this.eventBus.$emit('itemBasis', this.rfpBasis);
    },
    closeRfp() {
      if (this.rfpStatus.value === 'OPEN FOR BIDDING') {
        alert('Please close negotiation round!');
      } else {
        this.showOpenClosedRfpModal = true;
      }
    },
    closeRfpModal(flag) {
      this.showOpenClosedRfpModal = flag;
    },
    closeRfpConfirm(flag) {
      if (flag === 'yes') {
        if (this.remarks) {
          const payload = {
            remarks: this.remarks,
            rfp_header_id: this.rfpHeaderId
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/closeRfp', payload)
            .then(response => {
              if (response.status === 200) {
                this.responseModal = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getHeaderData();
              }
              this.loading = false;
            })
            .catch(err => {
              this.loading = false;
              this.makeToast(err, 'success');
            });
          this.showOpenClosedRfpModal = false;
        } else {
          alert('Please Enter Remarks first');
        }
      } else {
        this.showOpenClosedRfpModal = false;
      }
    },
    clearValueSetModal(type) {
      if (type === 'LEGAL_ENTITY_LIST') {
        this.leName = {
          value: null,
          text: null
        };
      } else if (type === 'USER_DEPARTMENT') {
        this.userDepartment = {
          value: null,
          text: null
        };
      } else if (type === 'RFP_TYPE') {
        this.rfpType = {
          value: null,
          text: null
        };
      } else if (type === 'PAYMENT_TERMS') {
        this.paymentTerm = {
          value: null,
          text: null,
          id: null
        };
      } else if (type === 'RFP BOQ TYPE') {
        this.boqType = {
          value: null,
          text: null
        };
        this.headerBudget = null;
        this.headerRetention = null;
      }
    },

    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: this.menuFromDashboard
          ? this.menuFromDashboard
          : this.$store.state.shared.menuId,
        sub_menu_id: 0,
        record_id: this.rfpHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    formatDate() {
      let date = new Date();
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      this.startDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    addMonths(date, months) {
      let newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + months);
      if (newDate.getDate() !== date.getDate()) {
        newDate.setDate(0);
      }

      return newDate;
    },
    checkStartDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
      if (this.startDate < formattedDateTime) {
        alert('Start Date & Time cannot be less than Current Date & Time');
      }
    },
    checkDecimalPlaces(headerBudget) {
      if (this.boqType.text === 'New Project Work') {
        if (headerBudget > 0) {
          let budget = headerBudget.toString().split('.');
          if (budget.length > 1 && budget[0].length > 14) {
            this.headerBudget = null;
            alert('Only Fourteen digits allowed before decimal');
          } else if (budget.length > 1 && budget[1].length > 2) {
            this.headerBudget = parseFloat(this.headerBudget).toFixed(2);
            alert('Only Two Decimal Value Allowed.');
          } else if (budget.length === 1 && budget[0].length > 14) {
            this.headerBudget = null;
            alert('Only Fourteen digits allowed before decimal');
          }
        } else {
          alert('Budget should be greater than 0');
          this.headerBudget = null;
        }
      } else {
        let budget = headerBudget.toString().split('.');
        if (budget.length > 1 && budget[0].length > 14) {
          this.headerBudget = null;
          alert('Only Fourteen digits allowed before decimal');
        } else if (budget.length > 1 && budget[1].length > 2) {
          this.headerBudget = parseFloat(this.headerBudget).toFixed(2);
          alert('Only Two Decimal Value Allowed.');
        } else if (budget.length === 1 && budget[0].length > 14) {
          this.headerBudget = null;
          alert('Only Fourteen digits allowed before decimal');
        }
      }
    },
    getPaymentTermsData() {
      const payload = {
        _page: 0,
        _limit: 1000,
        payment_term_name: this.paymentTerm.value,
        payment_term_desc: this.paymentTerm.text
      };
      this.$store
        .dispatch('intWorkflow/getPaymentTerms', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 201) {
            this.paymentTermsData = response.data.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedPaymentTerm(flag, paymentTerm) {
      this.showOpenPaymentTerms = flag;
      this.paymentTerm = {
        value: paymentTerm.payment_name,
        text: paymentTerm.description,
        id: paymentTerm.payment_term_id
      };
    },
    cancelRfp() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        remarks: 'CANCEL'
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/cancelRfp', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.makeToast(response.data.message, 'success');
            this.getHeaderData();
          }
        })
        .catch(() => {
          this.loading = false;
          this.makeToast('Something went wrong', 'danger');
        });
    },
    openCancelRfpModal() {
      this.showOpenCancelRfpModal = true;
    },
    cancelRfpModal(flag) {
      this.showOpenCancelRfpModal = flag;
    },
    cancelRfpConfirm(flag) {
      if (flag === 'yes') {
        if (this.cancelRemarks) {
          const payload = {
            rfp_header_id: this.rfpHeaderId,
            remarks: this.cancelRemarks
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/cancelRfp', payload)
            .then(response => {
              this.loading = false;
              if (response.status === 200) {
                this.makeToast(response.data.message, 'success');
                this.getHeaderData();
              }
            })
            .catch(() => {
              this.loading = false;
              this.makeToast('Something went wrong', 'danger');
            });
          this.showOpenCancelRfpModal = false;
        } else {
          alert('Please Enter Remarks first');
        }
      } else {
        this.showOpenCancelRfpModal = false;
      }
    },
    showOpenOwnersModal(flag) {
      this.getOwnersData();
      this.ownersModal = flag;
    },
    getBuyersData(flag, type) {
      this.selectedRow = [];
      if (type === 'modal') {
        this.selectedBuyersData = [];
        this.searchOwner = null;
      }
      {
        if (flag) {
          const payload = {
            _page: 0,
            _limit: 1000,
            buyer_name: this.searchOwner
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/getBuyersData', payload)
            .then(response => {
              this.loading = false;
              if (response.status === 200) {
                this.buyersData = response.data.data.page;
              }
            })
            .catch(() => {
              this.loading = false;
              this.makeToast('Something went wrong', 'danger');
            });
        }
        this.selectBuyersModal(flag);
      }
    },
    selectBuyersModal(flag) {
      this.getOwnersData();
      this.buyersModal = flag;
    },
    selectBoxChecked(flag, index, item, buyerCode) {
      this.buyersData[index].selectBox = flag;
      if (flag === true) {
        let count = 0;
        for (let i of this.ownersData) {
          if (i.owner_code === buyerCode) {
            count = count + 1;
          }
        }
        if (count > 0) {
          alert('Owner already exist');
          this.buyersData[index].selectBox = false;
        } else {
          this.selectedRow.push(item);
          this.selectedBuyersData.push(item);
          if (this.selectedRow.length === this.buyersData.length) {
            this.selectAllCheckBox = true;
          }
        }
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.buyer_code !== buyerCode
        );
        this.selectedBuyersData = this.selectedRow.filter(
          el => el.buyer_code !== buyerCode
        );
        this.selectAllCheckBox = false;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.buyersData = this.buyersData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.buyersData;
      } else {
        this.buyersData = this.buyersData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
      }
    },
    saveOwnersData() {
      let ownerDetails = [];
      for (let i of this.selectedBuyersData) {
        ownerDetails.push({
          is_delete: 0,
          rfp_owner_id: 0,
          buyer_name: i.buyer_name,
          buyer_code: i.buyer_code,
          buyer_access: i.write_access ? 1 : 0,
          buyer_email: i.buyer_email
        });
      }
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        rfp_owner_dtls: ownerDetails
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postOwnersData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.makeToast('Request Completed Successfully', 'success');
          }
        })
        .catch(() => {
          this.loading = false;
          this.makeToast('Something went wrong', 'danger');
        });
    },
    getOwnersData() {
      const payload = {
        rfp_header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getOwnersData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.ownersData = response.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
          this.makeToast('Something went wrong', 'danger');
        });
    },
    getWriteAccess() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        user_id: this.userId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getWriteAcces', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.rfpWriteAccess = response.data.data[0].rfp_write_access;
            if (this.rfpWriteAccess === 0) {
              this.rfpClosedFlag = true;
              this.eventBus.$emit('rfpClosedFlag', this.rfpClosedFlag);
            }
          }
        })
        .catch(() => {
          this.loading = false;
          // this.makeToast('Something went wrong', 'danger');
        });
    },
    updateOwnersData() {
      let ownerDetails = [];
      for (let i of this.ownersData) {
        ownerDetails.push({
          is_delete: i.remove ? 1 : 0,
          rfp_owner_id: i.rfp_owner_id,
          buyer_name: i.owner_name,
          buyer_code: i.owner_code,
          buyer_access: i.owner_access ? 1 : 0,
          buyer_email: i.owner_email
        });
      }
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        rfp_owner_dtls: ownerDetails
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postOwnersData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.makeToast('Request Completed Successfully', 'success');
            this.getOwnersData();
          }
        })
        .catch(() => {
          this.loading = false;
          this.makeToast('Something went wrong', 'danger');
        });
    },
    removeUnremoveOwner(index) {
      if (this.ownersData[index].remove) {
        this.ownersData[index].remove = false;
      } else {
        this.ownersData[index].remove = true;
      }
    },
    openQnaModal(flag) {
      this.getQuestionsFromVendors();
      this.qnaModal = flag;
    },
    getQuestionsFromVendors() {
      const payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_hdr_id: this.negotiationAllData[this.negotiationAllData.length-1].negotiation_hdr_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getQuestionsFromVendors', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.messageCount = 0;
            for(let i of result) {
              if(i.answer_text === null) {
                this.messageCount=this.messageCount+1;
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {
    this.eventBus.$off('userDepartment');
    this.eventBus.$off('rfpClosedFlag');
    this.eventBus.$off('itemBasis');
    this.eventBus.$off('rfpStatus');
    this.eventBus.$off('headerData');
    this.eventBus.$off('rfpExtensionId');
  }
};
